import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function serviceWithData(WrappedComponent, options = {}) {
  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.wrapped = graphql(gql `
            query PageServiceQuery {
              thisService: pageServices(q:"fields.route=${props.match.params.matchedRoute}"){
                route
                name
                introBackground {
                  url
                }
                introBody
                hero {
                  url
                  title
                  description
                }
                caseStudies {
                  route
                  name
                  marketingImage {
                    title
                    url
                  }
                  venue {
                    name
                    route
                  }
                  relatedServices {
                    name
                    route
                  }
                }
                footerCaseStudies {
                  name
                  route
                  marketingImage {
                    title
                    url
                  }
                  relatedServices {
                    route
                    name
                  }
                }
                footerVenuesLink {
                  route
                  introHeader
                  introBody
                  marketingImage {
                    title
                    url
                  }
                }
                footerNextService {
                  name
                  route
                  introBody
                  hero {
                    title
                    url
                  }
                }
                bodySections {
                  __typename
                  ... on ModuleAside {
                    header
                    body
                    linkText
                    linkUrl
                    bulletPoints
                    isWide
                  }
                  __typename
                  ... on ModuleConcerts {
                    header
                    headliners
                    images {
                      title
                      description
                      url
                    }
                    supportingActs
                  }
                  __typename
                  ... on ModuleFeatures {
                    header
                    body
                    linkText
                    linkUrl
                    features {
                      image {
                        title
                        url
                      }
                      header
                      description
                      linkUrl
                      videoUrl
                    }
                  }
                  __typename
                  ... on ModuleGallery {
                    headerBodyTextLocation
                    header
                    body
                    linkText
                    linkUrl
                    images {
                      title
                      url
                    }
                    fullWidthBottomImage
                    caption
                    image360 {
                      url
                      title
                      sys {
                        id
                      }
                    }
                  }
                  __typename
                  ... on ModuleQuote {
                    header
                    body
                    linkText
                    linkUrl
                    quote {
                      quote
                      attribution
                      relevance
                      image {
                        title
                        url
                      }
                      isGreen
                      isTweet
                    }
                  }
                  __typename
                  ... on ModuleStats {
                    header
                    body
                    linkText
                    linkUrl
                    stats {
                      prefix
                      finalAmount
                      abbreviation
                      description
                    }
                    textOnTop
                  }
                }
              }
              footer: globalSites {
                mainNavigation {
                  footerHeader
                  footerBody
                  footerLinks {
                    __typename
                    ... on PageFreeText {
                      title
                      route
                    }
                  }
                  buttonCTA
                  phoneNumber
                  copyrightHolder
                }
                contactUsPage {
                  route
                }
              }
            }
        `, { name: 'pageServiceQuery' })(WrappedComponent);
    }

    render() {
      const Wrapped = this.wrapped;
      return <Wrapped {...this.props}/>;
    }
  };

  return HOC;
}