import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom'
import PageTransition from './PageTransition';

import withTracker from './components/withTracker';

import homeWithData from './pages/Home/homeWithData';
import Home from './pages/Home/Home';

import pageOurVenuesWithData from './pages/PageOurVenues/pageOurVenuesWithData';
import PageOurVenues from './pages/PageOurVenues/PageOurVenues';

import venueWithData from './pages/Venue/venueWithData';
import Venue from './pages/Venue/Venue';

import pageWhatWeDoWithData from './pages/PageWhatWeDo/pageWhatWeDoWithData';
import PageWhatWeDo from './pages/PageWhatWeDo/PageWhatWeDo';

import serviceWithData from './pages/Service/serviceWithData';
import Service from './pages/Service/Service';

import pageWhoWeAreWithData from './pages/PageWhoWeAre/pageWhoWeAreWithData';
import PageWhoWeAre from './pages/PageWhoWeAre/PageWhoWeAre';

import personWithData from './pages/Person/personWithData';
import Person from './pages/Person/Person';

import caseStudyWithData from './pages/CaseStudy/caseStudyWithData';
import CaseStudy from './pages/CaseStudy/CaseStudy';

import freeTextWithData from './pages/FreeText/freeTextWithData';
import FreeText from './pages/FreeText/FreeText';

import errorPageWithData from './pages/ErrorPage/errorPageWithData';
import ErrorPage from './pages/ErrorPage/ErrorPage';

import contactUsWithData from './pages/ContactUs/contactUsWithData';
import ContactUs from './pages/ContactUs/ContactUs';

import invoiceWithData from './pages/Invoice/invoiceWithData';
import Invoice from './pages/Invoice/Invoice';

import image360WithData from './pages/Image360/image360WithData';
import Image360 from './pages/Image360/Image360';

const Router = withRouter(({ location, history }) => (
  <PageTransition location={location.pathname} history={history}>
    <Switch location={location}>
      <Route exact path="/" component={homeWithData(Home)}/>
      <Route exact path="/our-venues" component={pageOurVenuesWithData(PageOurVenues)}/>
      <Route exact path="/our-venues/:matchedRoute" component={venueWithData(Venue)}/>
      <Route exact path="/our-partners" component={pageOurVenuesWithData(PageOurVenues)}/>
      <Route exact path="/our-partners/:matchedRoute" component={venueWithData(Venue)}/>
      <Route exact path="/what-we-do" component={pageWhatWeDoWithData(PageWhatWeDo)}/>
      <Route exact path="/what-we-do/:matchedRoute" component={serviceWithData(Service)}/>
      <Route exact path="/who-we-are" component={pageWhoWeAreWithData(PageWhoWeAre)}/>
      <Route exact path="/who-we-are/:matchedRoute" component={personWithData(Person)}/>
      <Route exact path="/case-studies/:matchedRoute" component={caseStudyWithData(CaseStudy)}/>
      <Route exact path="/pages/:matchedRoute" component={freeTextWithData(FreeText)}/>
      <Route exact path="/contact-us" component={contactUsWithData(ContactUs)}/>
      {/*<Route exact path="/invoice/:matchedRoute" component={invoiceWithData(Invoice)}/>*/}
      <Route exact path="/image360/:matchedRoute" component={image360WithData(Image360)}/>
      <Route path="/404" component={errorPageWithData(ErrorPage)}/>
      <Route path="*" component={errorPageWithData(ErrorPage)}/>
    </Switch>
  </PageTransition>
));

export default Router;