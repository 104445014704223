import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function pageWhatWeDoWithData(WrappedComponent, options = {}) {
  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.wrapped = graphql(gql `query pageWhatWeDoQuery {
        thisPageWhatWeDo: pageWhatWeDos(q: "fields.route=${props.match.path.slice(1)}") {
          route
          introHeader
          introBody
          introBackground {
            url
          }
          primaryServices {
            header
            body
            servicePage {
              route
            }
            linkText
            hero {
              url
            }
            videoUrl
            quote {
              quote
              attribution
              relevance
            }
          }
          categoryHeader
          categoryList {
            header
            categoryImage {
              url
            }
            servicePage {
              route
            }
          }
          venueHeader
          venueBody
          venueImage {
            url
          }
        }
        footer: globalSites {
          mainNavigation {
            footerHeader
            footerBody
            footerLinks {
              __typename
              ... on PageFreeText {
                title
                route
              }
            }
            buttonCTA
            phoneNumber
            copyrightHolder
          }
          contactUsPage {
            route
          }
        }
      }
      `, { name: 'pageWhatWeDoQuery' })(WrappedComponent);
    }

    render() {
      const Wrapped = this.wrapped;
      return <Wrapped {...this.props}/>;
    }
  };

  return HOC;
}