import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function errorPageWithData(WrappedComponent, options = {}) {
  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.wrapped = graphql(gql `
            query PageErrorPageQuery {
              thisErrorPage: pageFreeTexts(q:"fields.route=error-page"){
                route
                title
                description
                body
              }
              footer: globalSites {
                mainNavigation {
                  footerHeader
                  footerBody
                  footerLinks {
                    __typename
                    ... on PageFreeText {
                      title
                      route
                    }
                  }
                  buttonCTA
                  phoneNumber
                  copyrightHolder
                }
                contactUsPage {
                  route
                }
              }
            }
        `, { name: 'pageErrorPageQuery' })(WrappedComponent);
    }

    render() {
      const Wrapped = this.wrapped;
      return <Wrapped {...this.props}/>;
    }
  };

  return HOC;
}